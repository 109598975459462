import { ChangeDetectionStrategy, Component, EventEmitter, input, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'evland-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: [ './modal-container.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIcon,
  ],
})
export class ModalContainerComponent {
  title = input.required<string>();
  withFooter = input<boolean>();
  @Output() onClose = new EventEmitter<void>();

  onCloseClick(): void {
    this.onClose.emit();
  }
}
